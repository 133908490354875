var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column py-4"},[(_vm.account.company_name)?_c('div',[_c('h2',{staticClass:"view-title"},[_vm._v("Account: "+_vm._s(_vm.account.company_name))])]):_vm._e(),_c('div',{staticClass:"header-top"},[_c('h2',{staticClass:"view-title"},[_vm._v("Manage Users")]),_c('v-text-field',{staticClass:"search",attrs:{"label":"Search","prepend-icon":"mdi-magnify","clearable":""},on:{"click:clear":_vm.clearSearch},model:{value:(_vm.search.search),callback:function ($$v) {_vm.$set(_vm.search, "search", $$v)},expression:"search.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"sort-by":"id","sort-desc":_vm.sort_desc,"footer-props":{ 'items-per-page-options': _vm.items_per_page },"options":_vm.options,"server-items-length":_vm.total,"mobile-breakpoint":"0"},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [(item.profile.avatar)?_c('img',{staticClass:"avatar",attrs:{"src":item.profile.avatar,"alt":item.first_name}}):_c('img',{staticClass:"avatar",attrs:{"src":_vm.images.avatarImg,"alt":item.first_name}})]}},{key:"item.first_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.first_name)+" ")]}},{key:"item.last_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profile.last_name)+" ")]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col-view"},_vm._l((item.roles),function(role){return _c('span',{key:role.id,staticClass:"role"},[_vm._v(_vm._s(role.name))])}),0)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"cont-actions"},[_c('v-btn',{staticStyle:{"min-width":"20px !important","padding":"10px !important"},attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.goToPath('/support/organization/user/' + item.id)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiAccountEdit)+" ")])],1),(_vm.hasUserPermission('impersonate'))?_c('dialog-impersonate',{attrs:{"email":item.email}}):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.actionOptions),function(option,i){return _c('v-list-item',{key:i,attrs:{"to":option.route + '/' + item.id}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(option.icon)+" ")]),_c('span',[_vm._v(_vm._s(option.title))])],1)],1)}),1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }